import { TFunction } from 'i18next';
import { ColumnsType } from 'antd/lib/table/InternalTable';

import withLoadingSkeleton from '@/HOCs/withLoadingSkeleton';
import { ConvertibleInstrumentsData } from '@/features/captable/types';
import { formatNumber } from '@/utils/formatNumber';

export const convertibleInstrumentsColumns = (
  isLoading: boolean,
  currency: string,
  t: TFunction<'captable', 'overview'>,
): ColumnsType<ConvertibleInstrumentsData | Record<string, number>> => [
  {
    title: t('instrumentHolder'),
    dataIndex: 'shareholder',
    sorter: true,
    render: withLoadingSkeleton(isLoading, (val: string) => val),
  },
  {
    title: t('name'),
    dataIndex: 'name',
    sorter: true,
    render: withLoadingSkeleton(isLoading, (val: string) => val),
  },
  {
    title: t('principal'),
    dataIndex: 'principal_amount',
    sorter: true,
    render: withLoadingSkeleton(isLoading, (val: number) => <>{`${currency} ${formatNumber(val)}`}</>),
  },
  {
    title: t('interest'),
    dataIndex: 'interest_rate',
    sorter: true,
    render: withLoadingSkeleton(isLoading, (val: number) => (
      <>{`${formatNumber(val, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%`}</>
    )),
  },
  {
    title: t('valuationCap'),
    dataIndex: 'valuation_cap',
    sorter: true,
    render: withLoadingSkeleton(isLoading, (val: number) => <>{`${currency} ${formatNumber(val)}`}</>),
  },
  {
    title: t('discountPercentage'),
    dataIndex: 'discount',
    sorter: true,
    render: withLoadingSkeleton(isLoading, (val: number) => (
      <>{`${formatNumber(val, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%`}</>
    )),
  },
];

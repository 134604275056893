import { useTranslation } from 'react-i18next';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, ReferenceLine } from 'recharts';

import themeToken from '@lib/theme/tokens/index';

import VestingCustomTooltip from './VestingCustomTooltip';
import type { VestingScheduleData } from '../../types';

type Props = {
  data: VestingScheduleData[];
  currentVestingDate?: string;
  cliff?: number;
};

const VestingAreaChart = ({ data, currentVestingDate, cliff }: Props) => {
  const { t } = useTranslation('captable', {
    keyPrefix: 'overview',
  });

  return (
    <ResponsiveContainer width={'100%'} height={370}>
      <AreaChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <ReferenceLine
          x={currentVestingDate}
          label={{ position: 'insideTopLeft', value: t('currentVesting'), fill: 'green' }}
          stroke="green"
        />
        {cliff && (
          <ReferenceLine
            x={data?.[cliff]?.date}
            label={{ position: 'insideTopLeft', value: t('cliff'), fill: 'orange', dy: 20 }}
            stroke="orange"
          />
        )}
        <Tooltip content={<VestingCustomTooltip />} />
        <Area
          type="stepAfter"
          dataKey="shares_percentage"
          stroke={themeToken['branding-primary-6']}
          fill="#8884d8"
          strokeWidth={'3px'}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default VestingAreaChart;

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import Flex from 'antd/lib/flex';
import Typography from 'antd/lib/typography';
import styled from 'styled-components';

import type { FolderData } from '../types';

import { useGenerateFileUrl } from '../api/generateFileUrl';

import themeToken from '@lib/theme/tokens/index';

import FileShare from './FileShare';
import FileDownload from './DownloadFile';
import FileDelete from './FileDelete';

import { StyledDrawer } from '@/features/captable/components/Shared/StyledDrawer';

import FilesIcons from './FilesIcons';
import CloseIcon from '@/components/Icons/CloseIcon';
import { isIosMobileDevice } from '@/utils/isIosMobileDevice';

import { usePermissionsStore } from '@/stores/Permissions';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { useCreateAnalytics } from '../api/createAnalytics';
import EditFile from './EditFile';

const { Text } = Typography;

const BodyWrapper = styled('div')`
  background: ${themeToken['branding-natural-5']};
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  open: boolean;
  previewOpenDate: Date | undefined;
  isFileDownloading: boolean;
  setOpen: (open: boolean) => void;
  file: FolderData;
  uuid?: string;
  handleDownloadStart: () => void;
  handleDownloadEnd: () => void;
  setIsShareFileOpen: () => void;
  setIsDeleteModalOpen: () => void;
  setIsEditModalOpen: () => void;
  setPreviewOpenDate: Dispatch<SetStateAction<Date | undefined>>;
};

const FilePreview = ({
  open = false,
  previewOpenDate,
  setOpen,
  file,
  uuid,
  isFileDownloading,
  handleDownloadStart,
  handleDownloadEnd,
  setIsShareFileOpen,
  setIsDeleteModalOpen,
  setIsEditModalOpen,
  setPreviewOpenDate,
}: Props) => {
  const { t, i18n } = useTranslation('dataroom');
  dayjs.locale(i18n.language);
  const { permissions } = usePermissionsStore((state) => state);
  const { selectedCompany } = useSelectedCompanyStore((state) => state);

  const [url, setUrl] = useState('');
  const [fileExtension, setFileExtension] = useState('');

  const { data } = useGenerateFileUrl({
    company_id: selectedCompany?.cid as number,
    file_id: file.id,
    uuid,
  });

  const { mutate: createAnalyticsMutation } = useCreateAnalytics();

  const handleClose = () => {
    if (previewOpenDate) {
      createAnalyticsMutation({
        company_id: selectedCompany?.cid as number,
        payload: {
          hash: uuid,
          file_id: file.id,
          duration: (new Date().getTime() - previewOpenDate.getTime()) / 1000,
          downloaded: 0,
          viewed: 1,
        },
      });
    }

    setOpen(false);
  };

  const handlePdfPreview = () => {
    if (isIosMobileDevice()) {
      return `https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(url)}`;
    }
    return url;
  };

  useEffect(() => {
    if (data) {
      setUrl(data.data.url);
      setFileExtension(data?.data.file_extension);
    }
  }, [data]);

  useEffect(() => {
    if (setPreviewOpenDate && open) setPreviewOpenDate(new Date());
  }, [setPreviewOpenDate, open]);

  return (
    <StyledDrawer closable={false} className="drawer" placement="right" size="large" onClose={handleClose} open={open}>
      <Flex className="drawer-header" justify="space-between" align="center">
        <Flex align="center" gap={14}>
          <CloseIcon onClick={handleClose} />
          <FilesIcons mimeType={file?.file_mimetype.toLocaleLowerCase()} />
          <Flex vertical>
            <Text
              style={{
                fontSize: themeToken.fontSizeLG,
                color: themeToken['branding-primary-6'],
                fontWeight: 600,
                marginBottom: themeToken.marginXXS,
              }}
            >
              {file?.name}
            </Text>
            <Text
              style={{
                color: themeToken['branding-natural-6'],
                fontSize: themeToken.fontSize,
              }}
            >
              {`${t('fileName')}, ${dayjs(file?.created).format('MMMM D, YYYY, hh:mm A')}`}
            </Text>
          </Flex>
        </Flex>
        <Flex gap={8}>
          {!uuid && (
            <FileShare
              hasLabel={false}
              disabled={!permissions.includes('company.dataroom.management')}
              setIsShareFileOpen={setIsShareFileOpen}
            />
          )}

          <FileDownload
            hasLabel={false}
            fileId={file.id}
            file={file}
            entityType="file"
            uuid={uuid}
            disabled={
              (uuid ? !file.downloadable : !permissions.includes('company.dataroom.download')) || isFileDownloading
            }
            handleDownloadStart={handleDownloadStart}
            handleDownloadEnd={handleDownloadEnd}
          />

          {!uuid && (
            <FileDelete
              hasLabel={false}
              setIsDeleteModalOpen={setIsDeleteModalOpen}
              disabled={!permissions.includes('company.dataroom.management')}
            />
          )}

          {!uuid && (
            <EditFile
              hasLabel={false}
              disabled={!permissions.includes('company.dataroom.management')}
              setIsEditModalOpen={setIsEditModalOpen}
            />
          )}
        </Flex>
      </Flex>

      <Flex vertical className="drawer-body">
        <BodyWrapper style={{ overflowY: 'scroll' }}>
          {data && (
            <iframe
              title="file preview"
              src={
                fileExtension?.includes('pdf')
                  ? handlePdfPreview()
                  : `https://view.officeapps.live.com/op/embed.aspx?src=${data?.data.url}`
              }
              width="100%"
              height="100%"
              style={{
                border: 0,
              }}
              allowFullScreen={true}
            >
              {!fileExtension?.includes('pdf') && (
                <>
                  This is an embedded
                  <a target="_blank" rel="noreferrer" href="http://office.com">
                    Microsoft Office
                  </a>
                  document, powered by
                  <a target="_blank" rel="noreferrer" href="http://office.com/webapps">
                    Office Online
                  </a>
                </>
              )}
            </iframe>
          )}
        </BodyWrapper>
      </Flex>
    </StyledDrawer>
  );
};

export default FilePreview;

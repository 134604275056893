export default {
  investment: {
    simulateInvestmentRound: 'محاكاة الجولة الاستثمارية',
    scenarioModeling: 'نمذجة الجولة الاستثمارية',
    investmentRound: 'الجولة الاستثمارية',
    investmentRoundModelingCalculator: 'حاسبة نمذجة الجولة الاستثمارية',
    newInvestment: 'استثمار جديد',
    amount: 'المبلغ',
    percentage: 'النسبة',
    currently: 'حالياً',
    preMoneyValuation: 'تقييم ما قبل الاستثمار',
    investmentDate: 'تاريخ الاستثمار',
    optionPool: 'تجميع خيارات الأسهم',
    maintainOptionShares: 'الحفاظ على خيارات الأسهم',
    newTargetOption: 'خيارات الأسهم الجديدة',
    resetNumbers: 'إعادة تعيين الأرقام',
    submit: 'تقديم',
    selectDate: 'تحديد التاريخ',
    optionShares: 'خيارات الأسهم',
    changeInputs: 'تغيير المدخلات',
    addToDataRoom: 'إضافة الى غرفة البيانات',
    download: 'تحميل',
    postInvestmentSharesDistribution: 'توزيع حصص ما بعد الاستثمار',
    postMoneyValuation: 'التقييم ما بعد الاستثمار',
    optionsAvailable: 'خيارات الأسهم المتاحة',
    newInvestor: 'مستثمر جديد',
    sharePrice: 'سعر السهم',
    investorsEquity: 'ملكية المستثمر',
    convertibleInstruments: 'السندات القابلة للتحويل قبل التمويل',
    convertInstrumentOnAPreMoneyBasis: 'السندات القابلة للتحويل',
    instrumentName: 'اسم السند',
    principal: 'اصل الدين',
    interestAmount: 'مبلغ الفائدة',
    conversion: 'التحويل',
    valuationCap: 'سقف التقييم',
    discount: 'الخصم',
    shares: 'أسهم',
    share: 'سهم',
    valuationType: 'نوع التقييم',
    total: 'الإجمالي',
    sharesDistribution: 'توزيع الأسهم',
    name: 'الأسم',
    totalShares: 'اجمالي الأسهم',
    preInvestmentOwnership: 'الملكية قبل الاستثمار',
    postInvestmentOwnership: 'الملكية بعد الاستثمار',
    ownershipChange: 'تغير الملكية',
    effectivePreMoneyValuation: 'تقييم ماقبل الاستثمار الفعال',
    conversionAmountSum: 'مجموع مبلغ التحويل',
    newOptionPool: 'تجميع خيارات الأسهم جديد',
    preMoneySharesIssuedOutstanding: 'الاسهم الصادرة والممنوحة ما قبل الاستثمار',
    newEquityInvestment: 'الاستثمار الجديد',
    newCapitalInvestedInThisRound: 'المبلغ الجديد المستثمر في هذه الجولة',
    totalSharesIssuedToNewInvestors: 'إجمالي الأسهم المصدرة للمستثمرين الجدد',
    Capped: 'مبني على سقف التقييم',
    Discounted: 'مبني على الخصم',
  },

  exit: {
    simulateExitScenario: 'محاكاة نمذجة التخارج',
    scenarioModeling: 'نمذجة الجولة الاستثمارية',
    exitModeling: 'نمذجة التخارج',
    exitLiquidationModeling: 'نمذجة التخارج / مميزات التخارج',
    exitLiquidationModelingCalculator: 'حاسبة نمذجة التخارج / مميزات التخارج',
    exitLiquidationPrice: 'سعر التخارج',
    investmentDate: 'تاريخ الاستثمار',
    selectDate: 'تحديد التاريخ',
    totalCapitalContribution: 'اجمالي المساهمة في رأس المال',
    preferencePayout: 'عوائد مميزات التخارج',
    participation: 'عوائد الأسهم العادية',
    noteHolder: 'حامل السند',
    issueDate: 'تاريخ الاصدار',
    grossProceeds: 'مجموع العوائد',
    totalGrossProceeds: 'مجموع العوائد',
    preferredStock: 'أسهم ممتازة',
    class: 'فئة السهم',
    seniority: 'الأولوية',
    convertedToCommon: 'تحويل الى اسهم عادية',
    liquidationPreference: 'مميزات التخارج',
    totalPreferredStock: 'اجمالي الأسهم الممتازة',
    commonStock: 'اسهم عادية',
    issuedShares: 'الأسهم المصدرة',
    totalCommonStock: 'اجمالي الأسهم العادية',
    totalGrossProceedsPerClass: 'مجموع العوائد لكل فئة',
    capitalContribution: 'المساهمة في رأس المال',
    preference: 'المميزات',
    roi: 'العائد على الاستثمار',
    percentageOfProceeds: 'نسبة العوائد',
    totalGrossProceedsPerShareholder: 'مجموع العوائد لكل مساهم',
    shareholder: 'مساهم',
    changeInputs: 'تغيير المدخلات',
    addToDataRoom: 'إضافة الى غرفة البيانات',
    download: 'تحميل',
    sharePrice: 'سعر السهم',
    convertibleInstruments: 'السندات القابلة للتحويل قبل التمويل',
    interestAmount: 'مبلغ الفائدة',
    principal: 'اصل الدين',
    shares: 'أسهم',
    valuationCap: 'سقف التقييم',
    discount: 'الخصم',
    total: 'الإجمالي',
  },
};

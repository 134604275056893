import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Flex, Form, notification, Space, Tag, Typography } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';

import themeToken from '@lib/theme/tokens/index';

import { useCreateSharedLog } from '../api/createSharedLogs';

import CommonModal from '@/features/captable/components/Shared/CommonModal';
import { StyledInput } from '@/lib/theme/components/Input';
import { StyledButton } from '@/lib/theme/components/Button';
import { StyledForm } from '@/components/Shared/Form';
import { StyledDatePicker } from '@/lib/theme/components/DatePicker';

import ShareIcon from '@/components/Icons/ShareIcon';

import { CreateSharedLogPayload } from '../types';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { useOutletContext } from 'react-router-dom';
import { LimitsCheckerContext } from '@/components/LimitsChecker';

const { Text } = Typography;

const ShareFileForm = ({
  isShareFileOpen,
  handleCancel,
  dataroomIds,
  closeFilePreview,
}: {
  isShareFileOpen: boolean;
  handleCancel: () => void;
  dataroomIds: number[];
  closeFilePreview?: () => void;
}) => {
  const { t } = useTranslation('dataroom');
  const { recheckLimits, limitations, disableRecordsAddition } = useOutletContext<LimitsCheckerContext>() || {};
  const { selectedCompany } = useSelectedCompanyStore((state) => state);
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [emails, setEmails] = useState<string[]>([]);
  const { mutate: createSharedLogMutation, isPending } = useCreateSharedLog({
    api,
    successCallback: () => {
      handleCancel();
      form.resetFields();
      setEmails([]);
      closeFilePreview?.();
      recheckLimits();
    },
  });
  const emailInput = Form.useWatch<string>('email', form);

  const remainingShareLimit = useMemo(() => {
    if (!limitations) return 0;

    const sharePlanlimit = limitations.find(({ key }) => key === 'share');
    if (disableRecordsAddition || !sharePlanlimit) return 0;
    const { limit, usage } = sharePlanlimit;
    return limit - usage;
  }, [limitations, disableRecordsAddition]);

  const onFinish = (values: { email: string; expiry_date: Date }) => {
    const emailsPayload = emails.length ? emails : [emailInput];
    const payload: CreateSharedLogPayload = {
      emails: emailsPayload,
      expiryDate: values.expiry_date.toISOString(),
      dataroomIds,
    };

    createSharedLogMutation({ company_id: selectedCompany?.cid as number, payload });
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return emailRegex.test(email);
  };

  const handleAddEmail = () => {
    if (emailInput && isValidEmail(emailInput)) {
      setEmails((prevEmails) => {
        const newEmails = [...prevEmails, emailInput];
        return newEmails;
      });
      form.setFieldsValue({
        email: '',
      });
    }
  };

  const handleRemoveEmail = (removedEmail: string) => {
    setEmails((prevEmails) => {
      const filteredEmails = prevEmails.filter((email) => email !== removedEmail);
      return filteredEmails;
    });
  };

  return (
    <>
      {contextHolder}
      <CommonModal isModalOpen={isShareFileOpen} handleCancel={handleCancel}>
        <Flex gap={14}>
          <ShareIcon />
          <Text
            style={{
              color: themeToken['branding-primary-6'],
              fontWeight: 600,
              fontSize: themeToken.fontSizeLG,
              marginBottom: themeToken.marginXXS,
            }}
          >
            {t('shareFile')}
          </Text>
        </Flex>

        <Divider
          style={{
            marginBlock: themeToken.margin,
            borderColor: themeToken['branding-natural-3'],
          }}
        />

        <StyledForm form={form} layout="vertical" onFinish={onFinish}>
          <Flex
            align="start"
            vertical
            style={{
              flex: 1,
            }}
          >
            <Form.Item
              name="email"
              style={{
                width: '100%',
              }}
              label={t('receiverEmail')}
              required
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                emails.length === 0
                  ? {
                      required: true,
                      message: 'Please input your E-mail!',
                    }
                  : {},
              ]}
              extra={
                <Flex
                  style={{
                    marginBlockStart: 4,
                  }}
                  gap={8}
                >
                  <Text
                    style={{
                      color: themeToken['branding-primary-6'],
                      fontSize: themeToken.fontSize,
                    }}
                  >
                    {t('receivers')}:
                  </Text>
                  <Space wrap>
                    {emails.map((email, i) => (
                      <Tag
                        key={i}
                        closable
                        onClose={() => handleRemoveEmail(email)}
                        closeIcon={
                          <CloseOutlined
                            style={{
                              color: themeToken['branding-primary-3'],
                            }}
                          />
                        }
                        color={themeToken['branding-primary-1']}
                        style={{
                          margin: 0,
                          color: themeToken['branding-primary-6'],
                          borderColor: themeToken['branding-primary-3'],
                        }}
                      >
                        {email}
                      </Tag>
                    ))}
                  </Space>
                </Flex>
              }
            >
              <StyledInput
                autoComplete="off"
                placeholder={t('receiverEmail')}
                addonAfter={
                  <StyledButton
                    size="small"
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={handleAddEmail}
                    disabled={!isValidEmail(emailInput) || emails.length >= remainingShareLimit}
                  />
                }
              />
            </Form.Item>
            <Form.Item
              name="expiry_date"
              style={{
                width: '100%',
              }}
              label={t('shareExpiryDate')}
              rules={[
                {
                  required: true,
                  message: 'Please select expiry date',
                },
              ]}
            >
              <StyledDatePicker placeholder={t('selectDate')} />
            </Form.Item>

            <Divider
              style={{
                marginBlock: themeToken.margin,
                borderColor: themeToken['branding-natural-3'],
              }}
            />
            <Flex style={{ width: '100%' }} justify="space-between">
              <StyledButton
                ghost
                type="primary"
                onClick={() => {
                  handleCancel();
                  form.resetFields();
                }}
              >
                {t('back')}
              </StyledButton>
              <StyledButton disabled={isPending} type="primary" htmlType="submit">
                {t('submit')}
              </StyledButton>
            </Flex>
          </Flex>
        </StyledForm>
      </CommonModal>
    </>
  );
};

export default ShareFileForm;

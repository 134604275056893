export default {
  users: {
    usersManagement: 'Shareholders Management',
    usersList: 'Shareholders List',
    user: 'Shareholder',
    accessLevels: 'Access Levels',
    email: 'Email',
    createdDate: 'Created Date',
    status: 'Status',
    actions: 'Actions',
    addNewUser: 'Add New Shareholder',
    totalPersonalisedExperience:
      'Total Personalised Experience for your profile add your company info to get highest Value from Rasmal',
    type: 'Type',
    Individual: 'Individual',
    Institution: 'Institution',
    legalName: 'Legal Name',
    typeUserOrFounderLegalName: 'Type shareholder or founder legal name',
    typeEmailOfUser: 'type email of user example@example.com',
    position: 'Position',
    Founder: 'Founder',
    'Co-Founder': 'Co-Founder',
    'Legal counsel': 'Legal counsel',
    Investor: 'Investor',
    Employee: 'Employee',
    Advisor: 'Advisor',
    'Third-party': 'Third-party',
    addNewUsers: 'Add new Shareholders',
    editUser: 'Edit Shareholder',
    editUsers: 'Edit Shareholders',
    back: 'Back',
    submit: 'Submit',
    cancel: 'Cancel',
    userDetails: 'Shareholder Details',
    close: 'Close',
    active: 'Active',
    disabled: 'Disabled',
    invitationSent: 'Invitation Sent',
    resend: 'Resend',
    shareAccess: 'Share Access',
  },

  roles: {
    usersManagement: 'Shareholders Management',
    accessLevels: 'Access Levels',
    rolesPermissions: 'Access Levels',
    name: 'Name',
    description: 'Description',
    actions: 'Actions',
    addNewAccessLevel: 'Add New Access Level',
    editRolesAndPermissions: 'Edit Access Levels',
    accessLevelDetails: 'Access Level Details',
    permissions: 'Permissions',
    createdDate: 'Created Date',
    close: 'Close',
    addNewRolePermissions: 'Add New Access Level',
    totalPersonalisedExperience:
      'Total Personalised Experience for your profile add your company info to get highest Value from Rasmal',
    titleOfNewRole: 'Title of New Access Level',
    suggestedNames: 'Suggested Names:',
    giveThisRoleName: 'Give this role name',
    permissionSetup: 'Permission Setup',
    selectAll: 'Select All',
    manageUsers: 'Manage Shareholders',
    manageRoles: 'Manage Access Levels',
    captable: 'Captable',
    governance: 'Governance',
    equityList: 'Equity List',
    optionList: 'Option List',
    convertibleInstrumentsList: 'Convertible Instruments List',
    dashboard: 'Dashboard',
    dataroom: 'Dataroom',
    'Data room Main': 'Data room Main',
    'Data room Shareable': 'Data room Shareable',
    userDashboard: 'User Dashboard',
    runway: 'Runway',
    financialAnalysis: 'Financial Analysis',
    loanRepayments: 'Loan Repayments',
    scenarioModeling: 'Scenario Modeling',
    'Scenario Modeling Round': 'Scenario Modeling Round',
    'Scenario Modeling Exit': 'Scenario Modeling Exit',
    projectCashflow: 'Project Cashflow',
    'Add Equity': 'Add Equity',
    'Edit Equity': 'Edit Equity',
    'Delete Equity': 'Delete Equity',
    'Add Option': 'Add Option',
    'Edit Option': 'Edit Option',
    'Delete Option': 'Delete Option',
    addWarrant: 'Add Warrant',
    editWarrant: 'Edit Warrant',
    deleteWarrant: 'Delete Warrant',
    'Create a new Convertible Instruments': 'Create a new Convertible Instruments',
    'Delete Convertible Instruments': 'Delete Convertible Instruments',
    financialTools: 'Financial Tools',
    'Add Vesting': 'Add Vesting',
    'Edit Vesting': 'Edit Vesting',
    'Delete Vesting': 'Delete Vesting',
    vestingList: 'Vesting List',
    'Data room Shared Logs': 'Data room Shared Logs',
    'Data room Download': 'Data room Download',
    activityLogs: 'Activity Logs',
    minutesOfTheMeeting: 'Minutes of the Meeting',
    momTemplates: 'MOM Templates',
    'Data room Management': 'Data room Management',
    'Data room Share Button': 'Data room Share Button',
    'Data room Analytics': 'Data room Analytics',
    captableOverview: 'Captable Overview',
    valuation: 'Valuation',
    back: 'Back',
    submit: 'Submit',
    cancel: 'Cancel',
    Employees: 'Employees',
    Investors: 'Investors',
    Accountant: 'Accountant',
    'Co-founders': 'Co-founders',
    'Data room Admin': 'Data room Admin',
  },

  activity: {
    activityLogs: 'Activity Logs',
    action: 'Action',
    user: 'User',
    createdAt: 'Created at',
    ok: 'OK',
    reset: 'Reset',
  },
};

export default {
  dashboard: 'راسمال |  نظرة عامة',
  addNewCompany: 'راسمال |  إنشاء شركة جديدة ',
  investmentOverview: ' راسمال | استثماراتي',
  addOns: 'راسمال | الإضافات',
  consultationServices: 'راسمال | خدمات استشارية',
  consultationDetails: 'راسمال | خدمات استشارية |  تفاصيل الاستشاري',
  support: 'راسمال | الدعم',
  dataRoom: 'راسمال | غرفة البيانات',
  analytics: 'راسمال | غرفة البيانات | تحليلات',
  sharedLogs: 'راسمال | غرفة البيانات | سجلات المشاركة',
  profile: 'راسمال |  إدارة الملف الشخصي  ',
  companyReviews: {
    status: 'راسمال | تقارير الشركة',
    mainInfo: 'راسمال | تقارير الشركة | اضف المعلومات الأساسية  ',
    fiancialInfo: 'راسمال | تقارير الشركة | اضافة البيانات المالية',
    teamInfo: 'راسمال | تقارير الشركة | أضف معلومات الفريق  ',
    final: 'راسمال | تقارير الشركة | التسليم  ',
  },
  captable: {
    title: 'راسمال | جدول الملكية',
    captableOverview: 'راسمال  | نظرة عامة',
    equities: 'راسمال | الاسهم',
    addNewEquity: 'راسمال | الاسهم |  إضافة فئة جديدة',
    equityDetails: 'راسمال | الاسهم | تفاصيل الفئة ',
    issueShares: 'راسمال |الاسهم |  إصدار الأسهم',
    options: ' راسمال | خطط خيارات الاسهم',
    addNewOption: 'راسمال | خطط خيارات الاسهم | إضافة خطة جديد',
    grantOption: 'راسمال | خطط خيارات الاسهم | منح خيارات أسهم',
    optionDetails: 'راسمال | خطط خيارات الاسهم | تفاصيل خيارات الأسهم',
    convertibleInstruments: 'راسمال | السندات القابلة للتحويل',
    addNewConvertibleInstruments: 'راسمال | السندات القابلة للتحويل | اضافة السندات الجديدة القابلة للتحويل',
    vestingSchedule: 'راسمال |  جدول استحقاق الملكية',
    addNewVestingSchedule: 'راسمال |  جدول استحقاق الملكية | أضف جدول استحقاق',
    editVestingSchedule: 'راسمال |  جدول استحقاق الملكية | تعديل جدول استحقاق',
  },
  valuation: {
    title: 'راسمال | التقييم',
    dashboard: 'راسمال | لوحة التحكم',
    questionnaire: 'راسمال | الاستبيان',
    financial: 'راسمال | البيانات المالية',
    reports: 'راسمال | التقارير',
  },
  scenarioModeling: {
    parentTitle: 'راسمال | النمذجة',
    investmentRound: 'راسمال | نمذجة لجولة الاستثمار',
    exitModeling: 'راسمال | نمذجة التخارج',
  },
  shareholders: {
    shareholdersList: 'راسمال | قائمة المساهمين',
    accessLevels: 'راسمال | صلاحيات الوصول',
    activityLogs: 'Activity Logs - Shareholders | Rasmal',
  },
  login: 'راسمال | تسجيل الدخول',
  signup: 'راسمال | إنشاء حساب جديد',
  confirmAccount: 'راسمال | تأكيد الحساب',
  documentGenerator: {
    contracts: 'راسمال | العقود',
    addDocumentInfo: 'راسمال | العقود | اضف معلومات العقد',
    documentHistory: 'راسمال | العقود | العقود المنشئة  ',
    reviewDocument: 'راسمال | العقود | راجع العقد بحذر   ',
  },
};

export default {
  investment: {
    simulateInvestmentRound: 'Simulate investment round',
    scenarioModeling: 'Scenario Modeling',
    investmentRound: 'Investment Round',
    investmentRoundModelingCalculator: 'Investment Round Modeling Calculator',
    newInvestment: 'New Investment',
    amount: 'Amount',
    percentage: 'Percentage',
    currently: 'Currently',
    preMoneyValuation: 'Pre-money valuation',
    investmentDate: 'Investment Date',
    optionPool: 'Option pool',
    maintainOptionShares: 'Maintain option shares',
    newTargetOption: 'New target option',
    resetNumbers: 'Reset Numbers',
    submit: 'Submit',
    selectDate: 'select date',
    optionShares: 'Option Shares',
    changeInputs: 'Change Inputs',
    addToDataRoom: 'Add to Data Room',
    download: 'Download',
    postInvestmentSharesDistribution: 'Post-investment Shares Distribution',
    postMoneyValuation: 'Post-money valuation',
    optionsAvailable: 'Options Available',
    newInvestor: 'New Investor',
    sharePrice: 'Share Price',
    investorsEquity: "Investor's Equity",
    convertibleInstruments: 'Convertible Instruments',
    convertInstrumentOnAPreMoneyBasis: 'Convert instrument on a pre-money basis',
    instrumentName: 'Instrument Name',
    principal: 'Principal',
    interestAmount: 'Interest Amount',
    conversion: 'Conversion',
    valuationCap: 'Valuation Cap',
    discount: 'Discount',
    shares: 'Shares',
    share: 'Share',
    valuationType: 'Valuation Type',
    total: 'Total',
    sharesDistribution: 'Shares Distribution',
    name: 'Name',
    totalShares: 'Total Shares',
    preInvestmentOwnership: 'Pre-investment Ownership',
    postInvestmentOwnership: 'Post-investment Ownership',
    ownershipChange: 'Ownership Change',
    effectivePreMoneyValuation: 'Effective Pre-money Valuation',
    conversionAmountSum: 'Conversion Amount Sum',
    newOptionPool: 'New Option Pool',
    preMoneySharesIssuedOutstanding: 'Pre-Money Shares Issued & Outstanding',
    newEquityInvestment: 'New Equity Investment',
    newCapitalInvestedInThisRound: 'New Capital Invested In This Round',
    totalSharesIssuedToNewInvestors: 'Total Shares Issued To New Investors',
    Capped: 'Capped',
    Discounted: 'Discounted',
  },

  exit: {
    simulateExitScenario: 'Simulate Exit Scenario',
    scenarioModeling: 'Scenario Modeling',
    exitModeling: 'Exit Modeling',
    exitLiquidationModeling: 'Exit / Liquidation Modeling',
    exitLiquidationModelingCalculator: 'Exit / Liquidation Modeling Calculator',
    exitLiquidationPrice: 'Exit/Liquidation Price',
    investmentDate: 'Investment Date',
    selectDate: 'select date',
    totalCapitalContribution: 'Total Capital Contribution',
    preferencePayout: 'Preference Payout',
    participation: 'Participation Payout',
    noteHolder: 'Note Holder',
    issueDate: 'Issue Date',
    grossProceeds: 'Gross Proceeds',
    totalGrossProceeds: 'Total Gross Proceeds',
    preferredStock: 'Preferred Stock',
    class: 'Class',
    seniority: 'Seniority',
    convertedToCommon: 'Converted to Common',
    liquidationPreference: 'Liquidation Preference',
    totalPreferredStock: 'Total Preferred Stock',
    commonStock: 'Common Stock',
    issuedShares: 'Issued Shares',
    totalCommonStock: 'Total Common Stock',
    totalGrossProceedsPerClass: 'Total Gross Proceeds Per Class',
    capitalContribution: 'Capital Contribution',
    preference: 'Preference',
    roi: 'ROI',
    percentageOfProceeds: 'Percentage of Proceeds',
    totalGrossProceedsPerShareholder: 'Total Gross Proceeds Per Shareholder',
    shareholder: 'Shareholder',
    changeInputs: 'Change Inputs',
    addToDataRoom: 'Add to Data Room',
    download: 'Download',
    sharePrice: 'Share Price',
    convertibleInstruments: 'Convertible Instruments',
    interestAmount: 'Interest Amount',
    principal: 'Principal',
    shares: 'Shares',
    valuationCap: 'Valuation Cap',
    discount: 'Discount',
    total: 'Total',
  },
};

const MinusIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_676_5418)">
        <path
          d="M11.9999 8.66659H3.99995C3.63328 8.66659 3.33328 8.36659 3.33328 7.99992C3.33328 7.63325 3.63328 7.33325 3.99995 7.33325H11.9999C12.3666 7.33325 12.6666 7.63325 12.6666 7.99992C12.6666 8.36659 12.3666 8.66659 11.9999 8.66659Z"
          fill="#6430DA"
        />
      </g>
      <defs>
        <clipPath id="clip0_676_5418">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MinusIcon;

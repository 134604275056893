import { Dispatch, SetStateAction } from 'react';
import { Grid } from 'antd';
import type { AnyObject } from 'antd/es/_util/type';
import type { ColumnsType, TableProps } from 'antd/es/table';
import type { SorterResult } from 'antd/es/table/interface';

import { skeletonsLoading } from '@/utils/skeletonsLoading';
import { StyledTable } from '@/lib/theme/components/Table';
import type { SortDirection } from '@/types';

import CommonTableTitle from './CommonTableTitle';
import CommonTableSummary from './CommonTableSummary';

interface CustomTableProps<T> extends Omit<TableProps<T>, 'columns' | 'dataSource' | 'title'> {
  columns: ColumnsType<T>;
  dataSource?: T[];
  title?: string;
  paginationParams?: {
    pageSize: number;
    current: number;
    total: number;
  };
  setPaginationParams?: Dispatch<
    SetStateAction<{
      pageSize: number;
      current: number;
      total: number;
    }>
  >;
  setSortParams?: Dispatch<
    SetStateAction<
      | {
          sort_by: keyof T;
          sort_dir: SortDirection;
        }
      | undefined
    >
  >;
  summaryTitle?: string;
  summaryOffset?: number;
  summaryTitleOffset?: number;
  summaryValues?: string[];
  subTitle?: string;
  isLoading?: boolean;
  onRow?: (record: T) => { onClick: () => void };
}

function CommonTable<T extends AnyObject>({
  columns,
  dataSource,
  title,
  paginationParams = {
    pageSize: 10,
    current: 1,
    total: 0,
  },
  setPaginationParams,
  setSortParams,
  summaryTitle,
  summaryOffset,
  summaryTitleOffset,
  summaryValues,
  subTitle,
  isLoading,
  onRow,
  ...props
}: CustomTableProps<T>) {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const fixedColumns = columns?.map((col, i) => ({
    ...col,
    fixed: i === 0 ? true : undefined,
  }));

  const processedDataSource = dataSource?.map((item, i) => ({
    key: (item?.id || i) as number,
    ...item,
  }));

  const onTableActionsChange: TableProps<T | Record<string, number>>['onChange'] = (
    pagination,
    _filters,
    sorter,
    extra,
  ) => {
    if (extra.action === 'paginate')
      if (setPaginationParams && pagination.current)
        setPaginationParams({
          ...paginationParams,
          current: pagination.current,
        });
    if (extra.action === 'sort') {
      const { order, field } = sorter as SorterResult<T>;
      if (setSortParams)
        setSortParams({
          sort_dir: order === 'ascend' ? 'asc' : 'desc',
          sort_by: field as keyof T,
        });
    }
  };

  const TableTitle = () => <CommonTableTitle title={title} subTitle={subTitle} />;

  const TableSummary = () =>
    summaryValues && (
      <CommonTableSummary
        summaryTitle={summaryTitle}
        summaryTitleOffset={summaryTitleOffset}
        summaryOffset={summaryOffset}
        summaryValues={summaryValues}
        isLoading={isLoading}
      />
    );

  return (
    <StyledTable
      columns={fixedColumns}
      dataSource={isLoading ? skeletonsLoading(10) : processedDataSource}
      pagination={paginationParams}
      title={title ? TableTitle : undefined}
      scroll={{ x: screens.lg ? '100%' : 'max-content' }}
      summary={TableSummary}
      onRow={onRow}
      onChange={props.onChange ?? onTableActionsChange}
      hasTitle={!!title}
      {...props}
    />
  );
}

export default CommonTable;

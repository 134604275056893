export default {
  dataRoom: 'غرفة البيانات',
  main: 'الأساسية',
  allCompanyFiles: 'جميع ملفات الشركة',
  yourFiles: 'ملفاتك',
  sharedLogs: 'سجلات المشاركة',
  description: 'الوصف',
  searchFileDocuments: 'البحث في الملفات، الوثائق، وما إلى ذلك',
  fileName: 'اسم الملف',
  sharedBy: 'تمت مشاركته من قبل',
  sharedTo: 'تمت مشاركته ل',
  expiryDate: 'تاريخ الانتهاء',
  expiredDate: 'تاريخ الانتهاء',
  sharedDate: 'تاريخ المشاركة',
  dataNotFound: 'البيانات غير موجودة',
  analytics: 'تحليلات',
  newFolder: 'مجلد جديد',
  share: 'مشاركة',
  delete: 'حذف',
  settings: 'إعدادات',
  createNewFolder: ' إنشاء مجلد جديد',
  folderName: 'اسم المجلد',
  download: 'تحميل',
  back: 'رجوع',
  submit: 'إرسال',
  watermark: 'علامة مائية',
  fieldRequired: 'هذه الخانة مطلوبه',
  userPermissions: 'صلاحيات المستخدم',
  shareFile: 'مشاركة الملف',
  selectDate: 'تحديد التاريخ',
  email: 'البريد الإلكتروني',
  receivers: 'المتلقون',
  'shared logs': 'سجلات المشاركة',
  receiverEmail: 'البريد الالكتروني للمتلقي',
  deleteWarning: 'انت على وشك حذف شئ مهم',
  deleteConfirmation: 'هل متأكد من حذف',
  file: 'ملف',
  yesDelete: 'نعم, حذف',
  viewCount: 'عدد المشاهدات',
  previewDuration: 'مدة المشاهدة',
  downloadCount: 'عدد التحميلات',
  lastModified: 'اخر تعديل',
  uploadFile: 'رفع الملف',
  rename: 'اعادة التسمية',
  open: 'فتح',
  copyTo: 'نسخ إلى',
  copyHere: 'إنسخ هنا',
  chooseFolderToCopyTo: 'اختر مجلد الوجهة',
  yes: 'نعم',
  cancel: 'الغاء',
  folderOptions: 'إعدادات المجلد',
  downloadable: 'قابل للتحميل',
  watermarked: 'ذو علامة مائية',
  shareExpiryDate: 'تاريخ إنتهاء المشاركة',
  selectAll: 'اختر الكل',
  itemSelected: '{{count}} عنصر محدد',
  itemsSelected: '{{count}} عناصر محددة',
};

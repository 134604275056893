import { useTranslation } from 'react-i18next';
import { PageWrapper } from '@/components/Shared/Wrapper';
import { Flex, Spin, Typography } from 'antd';
import themeToken from '@lib/theme/tokens/index';
import { StyledBreadcrumb } from '@/lib/theme/components/Breadcrumb';
import PresentationChartIcon from '@/components/Icons/PresentationChartIcon';
import Register from '../components/Dashboard/Register';
import Valuation from '../components/Dashboard/Valuation';
import { UseGetCompanyValuationStatus } from '../api/status';
import { UseGetCompanyDashboard } from '../api/Dashboard/getDashboard';
import NegativeValuation from '../components/Dashboard/NegativeValuation';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import StepsBanner from '../components/Dashboard/StepsBanner';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';

const { Title } = Typography;

const ValuationDashboard = () => {
  const { t } = useTranslation('valuation', {
    keyPrefix: 'dashboard',
  });

  const { t: translate } = useTranslation('pageTitles', {
    keyPrefix: 'valuation',
  });

  useDocumentTitle(translate('dashboard'));

  const selectedCompanyID = useSelectedCompanyStore((state) => state.selectedCompany?.cid);
  const { data: status, isLoading } = UseGetCompanyValuationStatus({
    urlParams: { companyID: selectedCompanyID as number },
  });

  const { data: companyValuationDashboard } = UseGetCompanyDashboard({
    urlParams: { companyID: selectedCompanyID as number },
    config: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      enabled: !!status?.valuation_registered,
    },
  });

  const isNegativeValuation = status?.valuation_negative;

  if (isLoading) {
    return (
      <Flex
        justify="center"
        align="center"
        style={{
          height: '100vh',
          width: '100%',
        }}
      >
        <Spin />
      </Flex>
    );
  }

  return (
    <Flex vertical>
      <Title
        level={5}
        style={{
          color: themeToken['branding-primary-6'],
        }}
      >
        {t('valuation')}
      </Title>

      <StyledBreadcrumb
        items={[
          {
            title: (
              <>
                <PresentationChartIcon width={12} height={20} />
                {t('valuation')}
              </>
            ),
          },
        ]}
      />
      {(!status?.financials || !status?.questionnaires) && status?.valuation_registered ? (
        <StepsBanner questionnaires={status?.questionnaires} financials={status?.financials} />
      ) : null}
      {status?.valuation_registered ? (
        isNegativeValuation ? (
          <PageWrapper>
            <Flex align="center" justify="center" flex={1}>
              <NegativeValuation />
            </Flex>
          </PageWrapper>
        ) : (
          <Valuation data={companyValuationDashboard?.data} />
        )
      ) : (
        <PageWrapper>
          <Flex align="center" justify="center" flex={1}>
            <Register />
          </Flex>
        </PageWrapper>
      )}
    </Flex>
  );
};

export default ValuationDashboard;

import { useTranslation } from 'react-i18next';

import { ChartSkeleton } from '@/components/Icons/ChartSkeleton';

import { Option } from '../../types';
import VestingGraphEmptyState from './VestingGraphEmptyState';
import VestingAreaChart from './VestingAreaChart';
import dayjs from 'dayjs';
import { OptionGrants } from '@/features/captable/types';

type Props = {
  graphData?: Option[] | OptionGrants[];
  selectedGrant: string;
  isLoading: boolean;
};

const VestingGraph = ({ graphData, selectedGrant, isLoading }: Props) => {
  const { i18n } = useTranslation('captable', {
    keyPrefix: 'overview',
  });
  dayjs.locale(i18n.language);
  const selectedGraphData = graphData?.find((option) => option.grant_name === selectedGrant);
  const vestingData = selectedGraphData?.vesting_graph?.vesting_schedule_data;
  const vestingDataFormatted = vestingData?.map((el) => ({ ...el, date: dayjs(el.date).format('MMM YYYY') }));
  const currentVestingDate = dayjs(selectedGraphData?.vesting_graph?.current_vesting_schedule_data?.date).format(
    'MMM YYYY',
  );
  const cliff = selectedGraphData?.vesting_schedule?.cliff ?? 0;
  if (isLoading)
    return (
      <div
        style={{
          width: '100%',
        }}
      >
        <ChartSkeleton />
      </div>
    );
  if (!vestingDataFormatted?.length) return <VestingGraphEmptyState />;
  return <VestingAreaChart data={vestingDataFormatted} currentVestingDate={currentVestingDate} cliff={cliff} />;
};

export default VestingGraph;
